<h1 class="md:text-7xl text-4xl text-center">{{name}}</h1>
<h1 class="text-center" style="white-space: pre">{{helpText}}</h1>

<canvas id="game-canvas">
</canvas>

<button [disabled]="playInterval != null" (click)="playSteps()">Play</button>
<button [disabled]="playInterval != null" (click)="doStep()">Single step</button>
<button (click)="reset()">Reset</button>

<br/><br/><br/>

<button routerLink="../">Back</button>

<button *ngIf="completedLevel" (click)="nextLevel()">Next</button>
