<div class="flex flex-col content-center">
  <div class="pb-5">
    <h1 class="md:text-7xl text-4xl">Cell Machine JS</h1>
  </div>
  <div class="m-auto pb-5">
    <button class="" routerLink="collection-selection">
      Play
    </button>
  </div>
  <div class="m-auto pb-5">
    <button routerLink="level-creator">
      Level creator
    </button>
  </div>
  <div class="m-auto pb-5">
    <button routerLink="canvas">
      Sandbox
    </button>
  </div>
</div>
