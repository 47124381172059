<div class="flex flex-col content-center">
  <div class="pb-5">
    <h1 class="md:text-7xl text-4xl text-center">{{collectionName}}</h1>
  </div>
  <div class="grid grid-cols-5 gap-4">
    <button *ngFor="let i of getLevelNumbers()" [routerLink]="'' + i">{{i}}</button>
  </div>
</div>
<br/>
<button routerLink="../">Back</button>
